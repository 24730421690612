import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import {
  MooringAnchorLine,
  MooringBridle,
  MooringBuoy,
  MooringGridLine,
  UpdateMooringAnchorLineDto,
  UpdateMooringBridleDto,
  UpdateMooringBuoyDto,
  UpdateMooringGridLineDto,
  MooringComponentCommentDto,
  CommentForEntity,
  CreateComponentCommentDto,
  UpdateComponentCommentDto,
  ResolveComponentCommentDto,
  AddMooringAnchorLineDto,
  AddMooringGridLineDto,
  AddMooringBuoyDto,
  AddMooringBridleDto,
  MooringDrawingSetup,
  MooringElementsListDto,
} from 'models/mooring';
import { MooringOfferDto } from 'models/mooring/MooringOffers';

@autoinject
export class MooringService {
  constructor(private client: NetlogHttpClient) {}

  async getMooring(id: number) {
    return await this.client.get<MooringOfferDto>({
      url: `api/moorings/${id}`,
      model: MooringOfferDto,
    });
  }

  async getMooringDrawingSetup(id: number) {
    return await this.client.get<MooringDrawingSetup>({
      url: `api/moorings/${id}/drawing-setup`,
      model: MooringDrawingSetup,
    });
  }

  async getMooringElementsList(id: number) {
    return await this.client.get<MooringElementsListDto>({
      url: `api/moorings/${id}/elements-list`,
      model: MooringElementsListDto,
    });
  }

  // ANCHOR LINES
  async getAnchorLines(mooringId: number) {
    return await this.client.get<MooringAnchorLine[]>({
      url: `api/moorings/${mooringId}/anchor-lines`,
      model: MooringAnchorLine,
    });
  }

  async addAnchorLine(mooringId: number, dto: AddMooringAnchorLineDto[]) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/anchor-lines`,
      data: dto,
    });
  }

  async deleteAnchorLine(mooringId: number, anchorLineId: number) {
    return await this.client.delete({
      url: `api/moorings/${mooringId}/anchor-lines/${anchorLineId}`,
    });
  }

  async updateAnchorLines(mooringId: number, dto: UpdateMooringAnchorLineDto[]) {
    return await this.client.patch({
      url: `api/moorings/${mooringId}/anchor-lines`,
      data: dto,
    });
  }

  // GRID LINES
  async getGridLines(mooringId: number) {
    return await this.client.get<MooringGridLine[]>({
      url: `api/moorings/${mooringId}/grid-lines`,
      model: MooringGridLine,
    });
  }

  async addGridLine(mooringId: number, dto: AddMooringGridLineDto[]) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/grid-lines`,
      data: dto,
    });
  }

  async deleteGridLines(mooringId: number, gridLineId: number) {
    return await this.client.delete({
      url: `api/moorings/${mooringId}/grid-lines/${gridLineId}`,
    });
  }

  async updateGridLines(mooringId: number, dto: UpdateMooringGridLineDto[]) {
    return await this.client.patch({
      url: `api/moorings/${mooringId}/grid-lines`,
      data: dto,
    });
  }

  // BUOYS
  async getBuoys(mooringId: number) {
    return await this.client.get<MooringBuoy[]>({
      url: `api/moorings/${mooringId}/buoys`,
      model: MooringBuoy,
    });
  }

  async addBuoy(mooringId: number, dto: AddMooringBuoyDto[]) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/buoys`,
      data: dto,
    });
  }

  async deleteBuoy(mooringId: number, buoyId: number) {
    return await this.client.delete({
      url: `api/moorings/${mooringId}/buoys/${buoyId}`,
    });
  }

  async updateBuoys(mooringId: number, dto: UpdateMooringBuoyDto[]) {
    return await this.client.patch({
      url: `api/moorings/${mooringId}/buoys`,
      data: dto,
    });
  }

  // BRIDLES
  async getBridles(mooringId: number) {
    return await this.client.get<MooringBridle[]>({
      url: `api/moorings/${mooringId}/bridles`,
      model: MooringBridle,
    });
  }

  async addBridle(mooringId: number, dto: AddMooringBridleDto[]) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/bridles`,
      data: dto,
    });
  }

  async deleteBridle(mooringId: number, bridleId: number) {
    return await this.client.delete({
      url: `api/moorings/${mooringId}/bridles/${bridleId}`,
    });
  }

  async updateBridles(mooringId: number, dto: UpdateMooringBridleDto[]) {
    return await this.client.patch({
      url: `api/moorings/${mooringId}/bridles`,
      data: dto,
    });
  }

  async getMooringComponentComments({ mooringId }: { mooringId: number }) {
    return await this.client.get<MooringComponentCommentDto[]>({
      url: `api/moorings/${mooringId}/components/comments`,
      model: MooringComponentCommentDto,
    });
  }

  async getComponentComments({ mooringId, entity, componentId }: { mooringId: number; componentId: number; entity: CommentForEntity }) {
    const params = new URLSearchParams();
    params.set('entity', entity.toString());

    return await this.client.get<MooringComponentCommentDto[]>({
      url: `api/moorings/${mooringId}/components/${componentId}/comments?${params.toString()}`,
      model: MooringComponentCommentDto,
    });
  }

  async createComponentComment({ mooringId, componentId, dto }: { mooringId: number; componentId: number; dto: CreateComponentCommentDto }) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/components/${componentId}/comments`,
      data: dto,
    });
  }

  async resolveComponentComment({ mooringId, commentId, dto }: { mooringId: number; commentId: number; dto: ResolveComponentCommentDto }) {
    return await this.client.post({
      url: `api/moorings/${mooringId}/components/comments/${commentId}/resolve`,
      data: dto,
    });
  }

  async deleteComponentComment({ mooringId, commentId }: { mooringId: number; commentId: number }) {
    return await this.client.delete({
      url: `api/moorings/${mooringId}/components/comments/${commentId}`,
    });
  }

  async updateComponentComment({ mooringId, commentId, dto }: { mooringId: number; commentId: number; dto: UpdateComponentCommentDto }) {
    return await this.client.patch({
      url: `api/moorings/${mooringId}/components/comments/${commentId}`,
      data: dto,
    });
  }

  updateOffer() {}
}
