import { autoinject } from 'aurelia-framework';
import { SearchResult } from 'elements/search/search-input';
import { AsyncErrorHandler } from 'lib/ui';
import { I18N } from 'aurelia-i18n';
import { MooringArticleService } from 'services';

type OnSelected = {
  result: SearchResult;
  item?: { ArticleId?: number; CategoryName: string; TypeName: string; ProductTypeName: string; MBL: number };
};

type GetArticleDetails = {
  index: number;
  articleId: number;
  item: { ArticleId?: number; ProductName: string };
};

@autoinject
export class MooringComponentSearchHandler {
  /**
   *
   */
  constructor(
    private mooringArticleService: MooringArticleService,
    private t: I18N
  ) {}

  @AsyncErrorHandler
  public async searchForArticleNumber(term: string) {
    const { data: result } = await this.mooringArticleService.search(term);
    if (!result || !result.length) return [];

    return result.map((x) => ({
      id: x.Id,
      title: `${x.Name} (${x.ArticleNumber})`,
      extra: [
        `${x.Category} / ${x.Type ?? ''} / ${x.ProductType ?? '-'}`,
        `${this.t.tr(`general.mbl`)}: ${x.MBL ?? '-'}`,
        `${this.t.tr(`general.supplier`)}: ${x.Supplier}`,
      ],
      return: x,
    }));
  }

  public onArticleNumberSelected({ result, item }: OnSelected) {
    item.ArticleId = result.id;
    item.CategoryName = result.return['Category'];
    item.TypeName = result.return['Type'];
    item.ProductTypeName = result.return['ProductType'];
    item.MBL = result.return['MBL'];
  }

  @AsyncErrorHandler
  public async getArticleNumberDetails(o: GetArticleDetails) {
    const { item, articleId } = o;

    if (item?.ProductName) {
      return {
        id: item.ArticleId,
        title: item.ProductName,
      };
    }

    const art = await this.mooringArticleService.get(articleId);
    return {
      id: art.Id,
      title: art.ArticleProducts?.[0].ProductName ?? '-',
    };
  }
}
